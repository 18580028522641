import React from "react"
import {Link} from "gatsby"
import EventSlider from "Layout/Events/EventSlider/EventSlider"
import RelatedEventCard from "Layout/Events/RelatedEvents/RelatedEventCard"
import "./RelatedEvents.scss"

const RelatedEvents = ({
  events,
  headline
}) => {

  const swiperSetting = {
    slidesPerView: 3,
    grabCursor: true,
    breakpoints: {
      // when window width is <= 767px
      767: {
        slidesPerView: 1
      }
    }
  }

  return (
    <div className="section section-related-events pt-2 pt-lg-1 pb-2 container-fluid bg-white">
      <div className="container">
        {
          events.length > 0 && headline && (
            <div className="row section-headline">
              <div className="col-12">
                <h2 className="bracket-text mb-2">{headline}</h2>
              </div>
            </div>
          )
        }
        {
          events.length > 3 ? (
            <EventSlider settings={swiperSetting} classes="row">
              {
                events.map(event => {
                  const eventCategories = event.node.categories_names.filter(item => item.taxonomy === "types")
                  const eventLocation = event.node.acf.event.event_venue && event.node.acf.event.event_venue
                  const eventYear = event.node.categories_names.filter(item => item.taxonomy === "years")

                  return (
                    <div
                      className="event-slide col-12 col-md-4 pb-2 px-0 text-sm"
                      key={event.node.title}
                    >
                      <Link
                        to={`/events/${event.node.slug}`}
                        key={event.node.slug}
                        data-location={eventLocation}
                        data-year={eventYear.length && eventYear[0].name}
                        data-type={eventCategories.length && eventCategories[0].name}
                      >
                        <div className="event-slide-inner">
                          <div className="event-header">
                            <div className="event-title">
                              <span className="bracket-text d-block">{eventCategories.length && eventCategories[0].name}</span>
                              <span>{event.node.title}</span>
                              {
                                event.node.acf.event.event_subtitle && <span>{` • ${event.node.acf.event.event_subtitle}`}</span>
                              }
                            </div>
                            <div className="event-info">
                              {event.node.acf.event.speaker_name && <h3 className="d-block" dangerouslySetInnerHTML={{ __html: event.node.acf.event.speaker_name }} />}
                              {
                                event.node.acf.event.event_date_info && (
                                  <span>
                                    {event.node.acf.event.event_date_info.event_date_info_start_date && event.node.acf.event.event_date_info.event_date_info_start_date}
                                    {event.node.acf.event.event_date_info.event_date_info_start_time && ` ${event.node.acf.event.event_date_info.event_date_info_start_time}`}
                                    {event.node.acf.event.event_date_info.event_date_info_end_date && ` – ${event.node.acf.event.event_date_info.event_date_info_end_date}`}
                                    {event.node.acf.event.event_date_info.event_date_info_end_time && ` ${event.node.acf.event.event_date_info.event_date_info_end_time}`}
                                  </span>
                                )
                              }
                              {eventLocation && <span className="d-block" dangerouslySetInnerHTML={{ __html: eventLocation }} />}
                            </div>
                          </div>
                          {
                            event.node.excerpt && event.node.excerpt.length && <div className="event-body mt-1" dangerouslySetInnerHTML={{ __html: event.node.excerpt }} />
                          }
                        </div>
                      </Link>
                    </div>
                  )
                })
              }
            </EventSlider>
          ) : (
            <div className="event-selection row">
              {
                events.map(event => {
                  return (
                    <RelatedEventCard
                      item={event.node}
                      key={event.node.title}
                      classes="mb-2 mb-md-2"
                    />
                  )
                })
              }
            </div>
          )
        }
      </div>
    </div>
  )
}

export default RelatedEvents

import React, {useState} from "react"
import {graphql} from "gatsby"
import MainLayout from "Layout/layout"
import IntroText from "Layout/IntroText/IntroText"
import RelatedEvents from "Layout/Events/RelatedEvents/RelatedEvents"

const SpeakerSeries = ({
  data,
  location
}) => {
  const {page, events, optionsPage} = data
  const gI = optionsPage.edges[0].node.global_data.generic_images

  const themes = page.acf.speaker_series_list.speaker_series_themes
  const sortedThemes = themes.slice().sort((a, b) => b.year - a.year)

  const handleDateFormatting = (year, month, day, time, period) => {
    let dateFormatted = ""
    const yearFormatted = year
    let monthFormatted = ""
    const dayFormatted = day.replace(",", "").length > 1 ? day.replace(",", "") : `0${day.replace(",", "")}`

    switch (month) {
      case "January":
        monthFormatted = "01"
        break

      case "February":
        monthFormatted = "02"
        break

      case "March":
        monthFormatted = "03"
        break

      case "April":
        monthFormatted = "04"
        break

      case "May":
        monthFormatted = "05"
        break

      case "June":
        monthFormatted = "06"
        break

      case "July":
        monthFormatted = "07"
        break

      case "August":
        monthFormatted = "08"
        break

      case "September":
        monthFormatted = "09"
        break

      case "October":
        monthFormatted = "10"
        break

      case "November":
        monthFormatted = "11"
        break

      case "December":
        monthFormatted = "12"
        break

      default:
        break
    }

    // time
    if(time && period) {
      const isPM = period === "pm"
      const timeFormatted = isPM ? parseFloat(time.split(":")[0]) + 12 : parseFloat(time.split(":")[0])

      dateFormatted = `${yearFormatted + monthFormatted + dayFormatted}T${timeFormatted}${time.split(":")[1]}00Z`
    } else {
      dateFormatted = `${yearFormatted + monthFormatted + dayFormatted}`
    }

    return dateFormatted
  }

  const [initialItems] = useState(events.edges ? events.edges : [])

  const filteredEvents = initialItems.filter(event => {
    const eventType = event.node.categories_names.filter(item => item.taxonomy === "types").length ? event.node.categories_names.filter(item => item.taxonomy === "types").map(item => item.name).toString() : ""
    return eventType !== "" && eventType === "Lecture"
  })

  return (
    <MainLayout location={location}>
      <IntroText intro={page.acf.intro_text} headline={page.title} genericImages={gI} classes="py-5" />
      {
        sortedThemes.map(theme => {
          const relevantEvents = filteredEvents.slice(0).filter(event => {
            const eventDateInfoStartDate = event.node.acf.event.event_date_info.event_date_info_start_date ? event.node.acf.event.event_date_info.event_date_info_start_date.split(" ") : false
            const eventDateInfoStartTime = event.node.acf.event.event_date_info.event_date_info_start_time ? event.node.acf.event.event_date_info.event_date_info_start_time.split(" ") : false
            const eventDateStartFormatted = eventDateInfoStartDate ? handleDateFormatting(eventDateInfoStartDate[2], eventDateInfoStartDate[0], eventDateInfoStartDate[1], eventDateInfoStartTime ? eventDateInfoStartTime[0] : false, eventDateInfoStartTime ? eventDateInfoStartTime[1] : false).substring(0, 4) : false

            return (eventDateStartFormatted === theme.year ? event : null)
          })

          return <RelatedEvents events={relevantEvents} headline={`${theme.title} ${theme.year}`} key={theme.year} />
        })
      }
    </MainLayout>
  )
}

export const query = graphql`
  query speakerSeriesPageQuery {
    page: wordpressPage(wordpress_id: {eq: 130}) {
      title
      slug
      acf {
        intro_text {
          paragraphs {
            paragraph
          }
        }
        speaker_series_list {
          speaker_series_themes {
            title
            year
          }
        }
      }
    }
    events: allWordpressWpEventPosts {
      edges {
        node {
          id
          slug
          title
          content
          excerpt
          locations
          years
          types
          categories_names {
            name
            taxonomy
          }
          acf {
            event {
              event_facts
              speaker_facts
              event_subtitle
              event_venue
              speaker_name
              event_date_info {
                event_date_info_end_date
                event_date_info_end_time
                event_date_info_start_date
                event_date_info_start_time
              }
              event_images {
                event_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1646) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SpeakerSeries
